import moment from 'moment';
import { useToast } from "vue-toastification";
const toast = useToast();
// 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// import { parse } from 'date-fns';
export default {
    methods: {
        removeImage() {
            this.images = '';
            this.imageUrl = this.images
        },
        openFileUploadDialog(id = '') {
            if (id ==''){
                id = 'fileid'
            }
            document.getElementById(id).click()
        },
        formatDate(date, format = '') {
            let newDate = date;
            if (format === '') {
                newDate = moment(date).format('DD/MM/YYYY');
                // const newDate = moment(date).format('L');
            } else if (format === 1) {
                newDate = moment(date).format('DD/MM/YYYY LT');
                // const newDate = moment(date).format('L LT');
            } else if (format === 2) {
                newDate = moment(date).format('LLLL');
            } else if (format == 3){
                const date2= this.toTimeZone(date)
                newDate = moment(date2).format('LLLL');
            } else if (format == 4){
                const date2= this.toTimeZone(date)
                newDate = moment(date2).format('DD/MM/YYYY LT');

            }
            if (newDate == 'Invalid date') {
                return date;
            } else {
                return newDate;
            }
        },
        toTimeZone(time) {
            const format = 'YYYY/MM/DD HH:mm:ss ZZ';
            return moment(time).utc().add(3,'hours').format(format)
        },
        async uploadMultipleImages(event, width, height, type = '') {
            // initiating loader
            const imageLoader = document.getElementById('foodie-loading-cover');
            imageLoader.style.opacity = .4;
            const loader = document.getElementById('foodie-loader');
            loader.style.display = 'block';
            const files = event.target.files || event.dataTransfer.files;

            if (!files.length) {
                toast.error("file too large");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return;
            }
            // put type validation

            // Allowing file type
            const allowedExtensions =
                /(\.jpg|\.jpeg|\.jpe|\.svg|\.png|\.gif|\.WEBP)$/i;
            // end typ
            const file = files[0];
            const fileName = file.name
            if (!allowedExtensions.exec(fileName)) {
                toast.error("cannot support the file type please use jpg,jpeg,png,gif or WEBP");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return false;
            }
            // const extension  = fileName.substring(fileName.lastIndexOf('.') + 1)

            const imageSize = file.size / 1000000
            if (type === 'item' && imageSize > 1) {
                toast.error("file too large")
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return
            }
            const reader = new FileReader();
            // Wait for the data url to be loaded from the file
            const dataURL = await new Promise(resolve => {
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });
            // wait for the image to be loaded
            const img = new Image();

            await new Promise(resolve => {
                img.onload = resolve;
                img.src = dataURL;
            });
            // Resize the image with a canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // This is an addition to keep the aspect ratio of the image so it won't get distorted
            const [imgWidth, imgHeight] = [
                img.naturalWidth,
                img.naturalHeight
            ];
            let nWidth = imgWidth
            let nHeight = imgHeight
            if (imgWidth > width) {
                const ratio = width / imgWidth;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            if (imgHeight > height) {
                const ratio = height / imgHeight;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            canvas.width = nWidth;
            canvas.height = nHeight;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const images = canvas.toDataURL('image/png', 0.9);

            const binary = atob(images.split(',')[1]);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            const blobData = new Blob([new Uint8Array(array)], { type: 'image/png' });

            const formData = new FormData();

            formData.append('image_file', blobData);
            const config = this.apiRequestConfig({
                'content-type': 'multipart/form-data'
            });
            const url = `${this.baseUrl}/add_image`;
            this.axios.post(url, formData, config).then((response) => {
                console.log('image response', response.data.image_url);
                if (response.status === 200) {
                    // this.url = images;
                    this.imageUrls.push( response.data.image_url)
                    this.imageUrl = response.data.image_url;
                    this.images = images;
                    loader.style.display = 'none';
                    imageLoader.style.opacity = 1;
                }
            })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
                        loader.style.display = 'none';
                        imageLoader.style.opacity = 1;
                    }
                })
        },
        async uploadImage(event, width, height, type = '') {
            // initiating loader
            const imageLoader = document.getElementById('foodie-loading-cover');
            imageLoader.style.opacity = .4;
            const loader = document.getElementById('foodie-loader');
            loader.style.display = 'block';
            const files = event.target.files || event.dataTransfer.files;

            if (!files.length) {
                toast.error("file too large");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return;
            }
            // put type validation

            // Allowing file type
            const allowedExtensions =
                /(\.jpg|\.jpeg|\.jpe|\.svg|\.png|\.gif|\.WEBP)$/i;
            // end typ
            const file = files[0];
            const fileName = file.name
            if (!allowedExtensions.exec(fileName)) {
                toast.error("cannot support the file type please use jpg,jpeg,png,gif or WEBP");
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return false;
            }
            // const extension  = fileName.substring(fileName.lastIndexOf('.') + 1)

            const imageSize = file.size / 1000000
            if (type === 'item' && imageSize > 3) {
                toast.error("file too large")
                loader.style.display = 'none';
                imageLoader.style.opacity = 1;
                return
            }
            const reader = new FileReader();
            // Wait for the data url to be loaded from the file
            const dataURL = await new Promise(resolve => {
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });
            // wait for the image to be loaded
            const img = new Image();

            await new Promise(resolve => {
                img.onload = resolve;
                img.src = dataURL;
            });
            // Resize the image with a canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // This is an addition to keep the aspect ratio of the image so it won't get distorted
            const [imgWidth, imgHeight] = [
                img.naturalWidth,
                img.naturalHeight
            ];
            let nWidth = imgWidth
            let nHeight = imgHeight
            if (imgWidth > width) {
                const ratio = width / imgWidth;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            if (imgHeight > height) {
                const ratio = height / imgHeight;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            canvas.width = nWidth;
            canvas.height = nHeight;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const images = canvas.toDataURL('image/png', 0.9);

            const binary = atob(images.split(',')[1]);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            const blobData = new Blob([new Uint8Array(array)], { type: 'image/png' });

            const formData = new FormData();

            formData.append('image_file', blobData);
            const config = this.apiRequestConfig({
                'content-type': 'multipart/form-data'
            });
            const url = `${this.baseUrl}/add_image`;
            this.axios.post(url, formData, config).then((response) => {
                console.log('image response', response.data.image_url);
                if (response.status === 200) {
                    // this.url = images;
                    this.imageUrl = response.data.image_url;
                    this.images = images;
                    loader.style.display = 'none';
                    imageLoader.style.opacity = 1;
                }
            })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
                        loader.style.display = 'none';
                        imageLoader.style.opacity = 1;
                    }
                })
        },
        formatPrice(value) {
            if (isNaN(value)) {
                return value
            } else {
                const val = (value / 1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
        },
        convertTime(time) {
            let hour = time % 12;
            if (time % 12 === 0) {
                hour = 12;
            }
            return hour;
        },
        exportCsv(data, filename) {
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
            anchor.target = '_blank';
            anchor.download = filename;
            anchor.click();
        },
        exportExcel(apiData, fileName) {
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        },
        downLoadMerchantRepotExcel(fileName,channelsales, summary){
            const wb = XLSX.utils.book_new();
            const wschannelsales = XLSX.utils.json_to_sheet(channelsales);
            XLSX.utils.book_append_sheet(wb, wschannelsales, "Sales per channel");
            const wssummary = XLSX.utils.json_to_sheet(summary);
            XLSX.utils.book_append_sheet(wb, wssummary, "Merchant Sales");
            const fileExtension = ".xlsx";
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data1, fileName + fileExtension);
        },
        downloadExcel(fileName, vouchers, payments, redemption, voidedgiftsvouchers, expiredgiftvouchers) {

            /* create a new blank workbook */
            const wb = XLSX.utils.book_new();

            /* create a worksheet for vouchers */
            const wsVouchers = XLSX.utils.json_to_sheet(vouchers);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsVouchers, "Gift Cards");

            /* create a worksheet for person details */
            const wspayments = XLSX.utils.json_to_sheet(payments);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wspayments, "Payments");

            /* create a worksheet for person details */
            const wsRedemptions = XLSX.utils.json_to_sheet(redemption);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsRedemptions, "Redemption History");
            /* create a worksheet for person details */
            const wsVoidedVouchers = XLSX.utils.json_to_sheet(voidedgiftsvouchers);

            /* Add the worksheet to the workbook */
            XLSX.utils.book_append_sheet(wb, wsVoidedVouchers, "Voided Gift Cards");

            /* create a worksheet for person details */
            const wsexpiredgiftcards = XLSX.utils.json_to_sheet(expiredgiftvouchers);

            /* Add the worksheet to the workbook */
            const fileExtension = ".xlsx";
            XLSX.utils.book_append_sheet(wb, wsexpiredgiftcards, "Expired Gift Cards");
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data1, fileName + fileExtension);
        },
        async print(reference, type) {
            let redirectUrl = ''
            try {
                this.spinner = reference
                // 
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const payload = {
                    'reference': reference,
                    'merchant_id': this.merchantID,
                    'type': type
                };
                const url = `${this.dashBaseUrl}/print-voucher`;
                const response = await this.axios.post(url, payload, config);
                redirectUrl = response.data.data.url;
                this.spinner = false;
            } catch (error) {
                this.spinner = false; // 
                redirectUrl = ''
            }
            if (redirectUrl != '') {
                const a = document.createElement('a');
                a.target = '_blank';
                a.href = redirectUrl;
                a.click();
            }

        }
    }
}